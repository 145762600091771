@import url(http://fonts.googleapis.com/css?family=Lato:300,400,700,900,100italic,300italic,400italic);
@import url(https://fonts.googleapis.com/css?family=Oswald:700,400,300);
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
/****************************
*   FOR RESPONSIVENESS      *
****************************/
::selection {
  background: transparent;
  /* WebKit/Blink Browsers */
  color: #3e3f3a;
}
::-moz-selection {
  background: transparent;
  /* Gecko Browsers */
  color: #3e3f3a;
}
input {
    -webkit-appearance: searchfield;
}

body
{
  font-family: 'Josefin Sans', sans-serif !important;
}
:root {
  --bs-body-font-family: 'Josefin Sans', sans-serif; /* Replace 'Roboto' with your desired font */
}

.outlet-holder
{
  background: #EDEDED;
  border-radius: 20px;
}

*::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}
textarea::selection, textarea::-moz-selection{
  background: none;
  color: #3e3f3a;
}
table.dataTable > tbody > tr > .selected {
  background-color: #BFA253 !important;
  color: white;
}
.dtsp-searchPane table > tbody > tr.selected td{
  background-color: #BFA253 !important;
  border-radius: 10px 10px 10px 10px !important;
  color: white;
}
.dtsp-searchPane table > tbody > tr > td:last-child{  
  border-radius: 10px 10px 10px 10px !important; 
}
div.dtsp-searchPane div.dataTables_scrollBody {
  height: 215px !important;
  max-height: 215px !important;
  border-radius: 10px;
}
div.dtsp-searchPane #DataTables_Table_3_wrapper div.dataTables_scrollBody {
  height: 100px !important;
  max-height: 100px !important;
  border-radius: 10px;
}
div.dtsp-searchPane div.dataTables_wrapper:hover {
  border: 2px solid #BFA253 !important;
  border-radius: 10px !important;
}
div.dtsp-topRow div.dtsp-subRow1 button.dtsp-searchIcon{
  display: none !important;
}
div.dataTables_scrollBody table {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
div.dtsp-searchPane div.dtsp-topRow button {
  border: 2px solid #fff;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  margin-left: 5px;
}
div.dtsp-verticalContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}
 
div.dtsp-verticalContainer div.dtsp-verticalPanes,
div.dtsp-verticalContainer div.container{
    width: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0;
}
 
div.dtsp-verticalContainer div.dtsp-verticalPanes{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 16%;
}
 
div.dtsp-verticalPanes {
    margin-right: 20px;
}

div.dtsp-searchPane div.dataTables_wrapper {
  border: 2px #f0f0f0 solid;
  border-radius: 10px;
}

div.dtsp-title {
    margin-right: 0px !important;
    margin-top: 5px !important;
}
 
input.dtsp-search {
    min-width: 0px !important;
    padding-left: 18px !important;
    margin: 0px !important;
}
 
div.dtsp-verticalContainer div.dtsp-verticalPanes div.dtsp-searchPanes{
    flex-direction: column;
    flex-basis: 0px;
}
 
div.dtsp-verticalContainer div.dtsp-verticalPanes div.dtsp-searchPanes div.dtsp-searchPane{
    flex-basis: 0px;
}
 
div.dtsp-verticalContainer div.container{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 70%;
    height: 1236px;
    overflow-y: scroll;
    margin-top: -88px;
    margin-left: 14px;
}

div.dtsp-panesContainer button.dtsp-clearAll{
  border: 2px solid #fff;
  border-radius: 10px;
  font-family: 'Josefin Sans', sans-serif !important;
  color: #777777;
  width: 125px;
 }
 .dtsp-title{
  margin-top: 0px;
  padding-left: 3px !important;
 }
div.dtsp-panesContainer {
  border: 0px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  background: #F8F5F0;
  /*position: fixed !important;
  width: 16%;
  top: 35vh;*/
}
html {
  font-size: 14px;
}
@media only screen and (min-width: 480px) {
  html {
    font-size: 17px;
  }
}
@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) {
  p {
    font-size: 1em;
    margin-bottom: 1.8em;
  }
}
/****************************
*      BODY DEFAULTS        *
****************************/
body {
  font-size: 1em;
  font-family: 'Josefin Sans', sans-serif;
}
a {
  color: #BFA253;
}
a:hover {
  text-decoration: none;
  color: #BFA253;
}
/****************************
*      Buttons        *
****************************/
.btn {
  font-family: 'Josefin Sans', sans-serif;
}
.btn i {
  position: relative;
  top: -2px;
}
.btn.text-left {
  text-align: left;
}
.btn-brand {
  background: #37a6c4;
  color: #fff;
}
.btn-brand:hover {
  color: #fff;
  background: #2c849c;
}
.btn-dark {
  background: #272727;
  color: #fff;
}
.btn-dark:hover {
  color: #fff;
  background: #2c849c;
}
.btn-red {
  background: #4f5362;
  color: #fff;
}
.btn-red:hover {
  color: #fff;
  background: #383b46;
}
.btn-alt {
  background: #f0f0f0;
  color: #222;
}
.btn-alt:hover {
  color: #fff;
  background: #2c849c;
}
/****************************
*      Background Colors        *
****************************/
.bk-primary {
  background: #ffffff;
}
.bk-success {
  background: #ffffff;
}
.bk-warning {
  background: #ffffff;
}
.bk-danger {
  background: #ffffff;
}
.bk-info {
  background: #ffffff;
}
.bk-white,
.bk-light {
  background: none;
}
.bk-brand {
  background: #37a6c4;
}
.bk-dark {
  background: #222;
}
.bk-blue {
  background: #0010ce;
}
.bk-alt {
  background: #f7f7f7;
}
.bk-ghost {
  background: transparent;
}
.panel-footer {
  padding: 10px 15px;
  background-color: #777;
  color: #ffffff;
  border-top: none;
  border-radius: 4px;
  text-align: center;
  margin: 10px;
}
.stat-panel-number{
  font-size: 70px;
}
a.panel-footer{
  color: #ffffff !important;
}
.bk-img {
  position: relative;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/background.jpg);
}
.bk-fixed {
  background-attachment: fixed;
}
.hr-green {
  height: 2px;
  background: #37a6c4;
}
.hr-grey {
  height: 2px;
  background: #f0f0f0;
}
.hr-dashed {
  background-color: transparent;
  border-top: 1px dotted #edf0f5;
  color: transparent;
  height: 1px;
  margin: 20px 0;
}
.panel {
  overflow: hidden;
}
/****************************
*      Border        *
****************************/
.brdr {
  border: 1px solid #f0f0f0;
}
/****************************
*      Text size color        *
****************************/
.font-one {
  font-family: 'Josefin Sans', sans-serif;
}
.ui-widget {
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif;
  color: #777777 !important;
}
.text-bold, .ui-widget{
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif;
}
.text-normal {
  font-weight: normal;
}
.text-thin {
  font-weight: 100;
}
.text-lowercase {
  text-transform: none;
}
.text-sm {
  font-size: 13px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 1.6em;
}
.text-2x {
  font-size: 2em;
}
.text-3x {
  font-size: 2.4em;
}
.text-4x {
  font-size: 3em;
}
.text-white,
.text-light {
  color: #BFA253 !important;
}
.link-white a {
  color: #fff;
}
.text-grey {
  color: #ccc;
}
.text-dgrey {
  color: #818181;
}
.text-brand {
  color: #37a6c4;
}
.text-yellow {
  color: #ffff00;
}
.title .line {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 3px solid #222;
}
/****************************
*       Margins         *
****************************/
.mt {
  margin-top: 18px;
}
.mt-0x {
  margin-top: 0 !important;
}
.mt-2x {
  margin-top: 36px;
}
.mt-3x {
  margin-top: 54px;
}
.mt-4x {
  margin-top: 90px;
}
.mt-5x {
  margin-top: 144px;
}
.mb {
  margin-bottom: 18px;
}
.mb-0x {
  margin-bottom: 0 !important;
}
.mb-2x {
  margin-bottom: 36px;
}
.mb-3x {
  margin-bottom: 54px;
}
.mb-4x {
  margin-bottom: 90px;
}
.mb-5x {
  margin-bottom: 144px;
}
.pt {
  padding-top: 18px;
}
.pt-2x {
  padding-top: 36px;
}
.pt-3x {
  padding-top: 54px;
}
.pt-4x {
  padding-top: 90px;
}
.pt-5x {
  padding-top: 144px;
}
.pb {
  padding-bottom: 18px;
}
.pb-2x {
  padding-bottom: 36px;
}
.pb-3x {
  padding-bottom: 54px;
}
.pb-4x {
  padding-bottom: 90px;
}
.pb-5x {
  padding-bottom: 144px;
}
/* Content boxes */
.content-box {
  padding: 15px;
}
.block-anchor {
  display: block;
}
/****************************
*       Content Typography         *
****************************/
.content-format {
  line-height: 1.6em;
  font-weight: 400;
  letter-spacing: .01em;
  font-style: normal;
  word-wrap: break-word;
}
.content-format .h1,
.content-format .h2,
.content-format .h3,
.content-format .h4,
.content-format .h5,
.content-format .h6,
.content-format h1,
.content-format h2,
.content-format h3,
.content-format h4,
.content-format h5,
.content-format h6 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 40px;
}
.content-format .h1 a,
.content-format .h2 a,
.content-format .h3 a,
.content-format .h4 a,
.content-format .h5 a,
.content-format .h6 a,
.content-format h1 a,
.content-format h2 a,
.content-format h3 a,
.content-format h4 a,
.content-format h5 a,
.content-format h6 a {
  font-size: inherit;
}
h1.text-center{
  font-size: 50px !important;
}
select.input-sm{
  height: 46px !important;
}
.content-format .h2,
.content-format h2 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 4px solid #f0f0f0;
}
.content-format .h3,
.content-format h3 {
  font-size: 2em;
  line-height: 1.2;
  margin-bottom: 4px;
  margin: 1em 0 0.5em;
}
.content-format .h4,
.content-format h4 {
  font-size: 1.7em;
  line-height: 1.4;
  margin-bottom: 6px;
}
.content-format .h5,
.content-format h5 {
  font-size: 1.5em;
  line-height: 1.4;
  margin-bottom: 8px;
}
.content-format .h6,
.content-format h6 {
  font-size: 1.3em;
  line-height: 1.4;
  margin-bottom: 10px;
}
.content-format strong {
  font-weight: bold;
}
.content-format ul:not(.social-awesome) {
  list-style: disc outside;
  margin: 1.714285714rem 0 1.714285714rem;
  line-height: 1.714285714;
}
.content-format ul:not(.social-awesome) li {
  margin: 0 0 0 36px;
  margin: 0 1rem 0 2.571428571rem;
}
.content-format ul:not(.social-awesome).list-unstyled {
  list-style: none;
}
.content-format ul:not(.social-awesome).list-unstyled li {
  margin: 0 0 0 24px;
}
.content-format em {
  font-style: italic;
}
.content-format p {
  margin-bottom: 1.8em;
}
.content-format figure {
  margin-bottom: 42px;
}
.content-format figure .caption {
  color: #999;
  font-size: 14px;
}
.content-format img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.content-format .fa {
  color: #37a6c4;
  margin-right: 10px;
}
.content-format blockquote {
  border-left: 4px solid #c3c3c3;
  border-left: 4px solid rgba(51, 51, 51, 0.17);
  color: #707070;
  color: rgba(51, 51, 51, 0.7);
  font-size: 1.2em;
  font-style: italic;
  line-height: 1.6667;
  margin-bottom: 1em;
  padding-left: 0.7778em;
}
.content-format blockquote.double-quote {
  border-left: none;
  padding-left: 1em;
}
.content-format pre {
  background: #fff;
  border: 1px solid #ededed;
  color: #666;
  font-family: 'Josefin Sans', sans-serif;
  font-size: .9em;
  line-height: 1.714285714;
  margin: 24px 0;
  margin: 1.714285714rem 0;
  overflow: auto;
}
.content-format ol {
  margin: 24px 0 24px;
  margin: 1.714285714rem 0 1.714285714rem;
  line-height: 1.714285714;
  list-style: decimal outside;
}
.content-format img.wp-smiley,
.content-format img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 .07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}
label.member-label{
  font-weight: normal !important;
  text-align: left !important;
}
/*
 * Theme Struck - Harmony - Free responsive Bootstrap admin template by Themestruck.com (http://themestruck.com)
 * Code licensed under the MIT
 * For details, see #
 */
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* Social Button Modifications */
.btn {
  text-transform: none;
}
.btn-social > :first-child {
  width: 42px;
  line-height: 46px;
}
.btn-social {
  padding-left: 58px;
}
/* Circle buttons */
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}
/* Chart design */
/* Charts */
.chart-dot-list {
  display: block;
  margin-top: 60px;
  margin-left: 20px;
}
.chart-dot-list li {
  margin-bottom: 4px;
}
.chart-dot-list li:before {
  content: '';
  height: 12px;
  width: 12px;
  margin-right: 6px;
  display: inline-block;
  background: #222;
  border-radius: 50%;
}
.chart-dot-list li.a1:before {
  background: #F7464A;
}
.chart-dot-list li.a2:before {
  background: #46BFBD;
}
.chart-dot-list li.a3:before {
  background: #FDB45C;
}
.chart-doughnut {
  padding: 41px 0;
}
.form-control[readonly]{
  background: none !important;
}
/* component */
.page-title {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 40px !important;
}
.brand {
  background: #ffffff;
  position: relative;
  z-index: 3;
  position: fixed;
  width: 100%;
  top: 0;
}
.brand .logo {
  box-sizing: border-box;
  padding: 20px;
  float: left;
  display: block;
  width: 200px;
}
@media only screen and (min-width: 992px) {
  .brand .logo {
    width: 250px;
  }
}
.brand .logo img {
  height: 20px;
}
.brand .menu-btn {
  float: right;
  background: #232d3b;
  text-align: center;
  cursor: pointer;
  color: #fff;
  padding: 20px 20px;
}
@media only screen and (min-width: 992px) {
  .brand .menu-btn {
    display: none;
  }
}
.brand .ts-profile-nav {
  float: right;
  display: none;
}
.brand .ts-profile-nav li {
  float: left;
  position: relative;
}
.brand .ts-profile-nav li a {
  display: block;
  padding: 20px;
  color: #777777;
}
.brand .ts-profile-nav li ul {
  visibility: hidden;
  opacity: 0;
  right: 0;
  position: absolute;
}
.brand .ts-profile-nav li ul li {
  float: none;
  width: 180px;
}
.brand .ts-profile-nav li ul li a {
  padding: 10px 20px;
  background: #ffffff;
}
.brand .ts-profile-nav .ts-account .fa-angle-down {
  float: right;
}
.brand .ts-profile-nav .ts-account > a {
  width: 180px;
  background: #ffffff;
}
.brand .ts-profile-nav .ts-account .ts-avatar {
  border-radius: 0%;
  height: 28px;
  margin: -10px 0;
  margin-right: 10px;
}
.brand .ts-profile-nav .ts-account:hover ul {
  visibility: visible;
  opacity: 1;
}
@media only screen and (min-width: 992px) {
  .brand .ts-profile-nav {
    display: block;
  }
}
.ts-sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}
.ts-sidebar::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.ts-sidebar::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
}
.ts-sidebar {
  background: #ffffff;
  max-height: 0;
  transition: max-height 0.3s;
  overflow: hidden;
  position: fixed;
  z-index: 2;
  width: 100%;
  bottom: 0;
  top: 60px;
  overflow-y: auto;
}
.ts-sidebar .hidden-side {
  display: none;
}
.ts-sidebar .ts-sidebar-search {
  width: 100%;
  background: #25292a;
  border: none;
  padding: 10px 15px;
  color: #777777;
}
@media only screen and (min-width: 992px) {
  .ts-sidebar .ts-profile-nav {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .ts-sidebar {
    max-height: 100%;
    bottom: 0;
  }
}
.ts-sidebar-menu .ts-label {
  color: #585c64;
  font-weight: bold;
  padding: 8px 15px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 3px;
  margin-top: 10px;
}
.ts-sidebar-menu a {
  display: block;
  text-decoration: none;
  padding: 12px 15px;
  color: #777777;
}
.ts-sidebar-menu li {
  position: relative;
}
.ts-sidebar-menu li i {
  color: #BFA253;
  margin-right: 10px;
}
.ts-sidebar-menu li ul {
  display: block;
  background: rgba(255, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.32);
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}
.menu-open,
.ts-sidebar-menu .open > ul {
  max-height: 2000px;
}
.ts-sidebar-menu .open > a {
  background: rgba(51, 56, 62, 0.35);
}
.ts-sidebar-menu > .open > a {
  background: #33383e;
  border-left: 3px solid #37a6c4;
}
.ts-sidebar-menu .open > .more i {
  transform: rotate(180deg);
}
.more i {
  color: #fff;
  padding: 15px;
  transition: transform 0.3s;
}
.more {
  float: right;
  min-width: 10%;
  cursor: pointer;
}
.ts-main-content .content-wrapper {
  padding-top: 15px;
  margin-top: 72px;
}
@media only screen and (min-width: 992px) {
  .ts-main-content .ts-sidebar {
    width: 250px;
    float: left;
  }
  .ts-main-content .content-wrapper {
    margin-left: 250px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background: #EDEDED;
  }
}
/* Login page */
.login-page {
  position: absolute;
  height: 100%;
  width: 100%;
}
/*Datatable Custom Design*/
.panel{
  background-color: #fff !important;
  border: none;
}

/*.repeater .col-sm-3{
  padding: 0px;
  padding-right: 30px;
}*/
.form-control, .dtsp-search {
  display: block;
  width: 100%;  
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #777777;
  background-color: #EDEDED !important;
  background-image: none;
  border: 2px solid #fff !important;
  border-radius: 10px;
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #777777;
  opacity: 1; /* Firefox */
  font-weight: normal;
}
#orglistadmin_length label{
  font-weight: bold;
}
.form-horizontal label, table th, table td{
  font-family: 'Josefin Sans', sans-serif !important;
}

.fa{
  color: #BFA253;
}
.btn-warning, .btn-warning:focus {
  color: #ffffff !important;
  background-color: #BFA253 !important;
  padding: 8px 0px;
  min-width: 150px;
  text-align: center;
  border-radius: 10px !important;
  border-width: 0 !important;
  font-family: 'Josefin Sans', sans-serif !important;
  }
.btn-warning:hover {
  color: #ffffff !important;
  background-color: #00573C !important;
  font-family: 'Josefin Sans', sans-serif !important;
}
.btn-success
{
  min-width: 150px;
  background-color: #00573C !important;
  border-width: 0 !important;
}
.text-warning
{
  color:#BFA253 !important;
}

.text-success, .text-warning:hover
{
  color:#00573C !important;
}
.btn-success:hover
{
  background-color: #BFA253 !important;
}
a:focus {
    outline: none !important;
    /* outline: 5px auto -webkit-focus-ring-color; */
    /* outline-offset: -2px; */
}
/*checkbox Styling*/

/* The container */
 .container {
    width: 80% !important;
  }
.chkcontainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: none;
  border:2px solid #BFA253;
  border-radius:4px;
}

.checkmarkd {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: none;
  border:2px solid #777777;
  border-radius:4px;
}

/* On mouse-over, add a grey background color */
.chkcontainer:hover input ~ .checkmark {
  background-color: none;
  border:2px solid #BFA253;
}

.chkcontainer:hover input ~ .checkmarkd {
  background-color: none;
  border:2px solid #777777;
}

/* When the checkbox is checked, add a blue background */
.chkcontainer input:checked ~ .checkmark {
  background-color: none ;
  border:2px solid #BFA253;
}
.chkcontainer input:checked ~ .checkmarkd {
  background-color: none ;
  border:2px solid #777777;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,.checkmarkd:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chkcontainer input:checked ~ .checkmark:after,.chkcontainer input:checked ~ .checkmarkd:after {
  display: block;
}

/* Style the checkmark/indicator */
.chkcontainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: 2px solid #BFA253;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.chkcontainer .checkmarkd:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: 2px solid #777777;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#tabs{
  margin-bottom: 50px
}

.ui-widget.ui-widget-content {
  border: 2px solid #fff !important;
}
.ui-widget-content { 
  background: #EDEDED !important;
  color: #333333;
}
.login-box{
  background-color:rgba(255, 255, 255, 0.7);
  border-radius: 20px;

}

input[readonly] {
  /*pointer-events: none;*/
  cursor: not-allowed;
}
.ui-state-active, .ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  border: 0px solid #BFA253 !important;
  border-bottom-width: 0px;
  background: #BFA253 !important;
  font-weight: normal;
  font-family: Arial,Helvetica,sans-serif;
  color: #ffffff !important;
  font-size: 14px !important;
}
.ui-state-default a{
  font-family: 'Josefin Sans', sans-serif !important;
  border-radius: 10px;
  font-size: 14px !important;
  border: 0px solid #00573C;

  background: #00573C;
  font-weight: normal;
  color: #fff !important;
}
.ui-widget-header {
  border: 0px solid #dddddd !important;
  background: none !important;
  color: #333333 !important;
  font-weight: bold;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{
  border: 1px solid #ffffff;
}
/*.repeater .col-sm-3 .control-label{
  display: none;
}
.repeater .form-group:first-child .col-sm-3 .control-label{
  display: block;
}*/
.ui-tabs .ui-tabs-nav {
  margin-left: 25px !important;  
  margin-right: 25px !important;
  margin-top: 25px !important;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor { 
  padding: 12px 0px !important;
  width: 150px;
  text-align: center;  
  line-height: 1.6;
  display: inline-block;
}
button:hover{
 background: none;
}
.btn:focus, .btn:active{
  outline: none !important;
}
.control-buttons i{
  font-size: 45px !important;
  color: #00573C;
  cursor: pointer;
}
.control-buttons i:hover{
  font-size: 45px !important;
  color: #BFA253;
}
.ui-tabs .ui-tabs-nav li {
  list-style: none;
  float: left;
  position: relative;
  top: 0;
  margin: 0 12px 0 0 !important;
  border: 0px solid red !important; 
  padding: 0px !important;
  white-space: nowrap;
  border-radius: 10px;
}
.select2-container--bootstrap4.select2-container--focus .select2-selection{
  box-shadow: none !important;
}
.ui-widget input, .ui-widget select, .ui-widget textarea{
  font-family: 'Josefin Sans', sans-serif !important;
}
.ui-tabs .ui-tabs-panel{
  padding: 3em 2em !important;
}
.form-horizontal #tabs label.control-label, label.control-label{
  text-align: left !important;
  padding-left: 17px;
}
/* Make Select2 boxes match Bootstrap3 as well as Bootstrap4 heights: */
.select2-container--bootstrap4 .select2-selection {
  width: 100%;
  background-color: #EDEDED !important;
  border: 2px solid #fff !important;
  border-radius: 10px !important;
}
.select2-container--bootstrap4 .select2-selection--single {
  height: calc(1.5em + .75rem + 15px) !important;
}
.select2-container--default .select2-selection--single {
  height: calc(1.5em + .75rem + 15px) !important;
}
.select2-container--default .select2-selection--multiple {
  height: auto !important;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered, .select2-container--default .select2-selection--single {
  padding-left: .75rem;
  line-height: calc(2.5em + .75rem) !important;
  color: #777777;
}
.select2-container--default .select2-selection--multiple {
  padding-left: .75rem;
  line-height: calc(2.5em + .75rem) !important;
  color: #777777;
}
.select2-container--default .select2-selection--single {
  background-color: #EDEDED;
  border: 2px solid #fff;
  border-radius: 10px;
}
.select2-container--default .select2-selection--multiple {
  background-color: #EDEDED;
  border: 2px solid #fff;
  border-radius: 10px;
}
.tooltip{
  opacity: 1 !important;
}
.tooltip button{
  border: none;
  background: transparent;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}
.fa-clipboard{
  color: rgb(191, 162, 83);
  font-size: 14px;
}
div:focus-visible{
  outline-width: 0;
}
*:focus,*:focus-visible, input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus  {
    outline: none !important;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/*Set border-radius on the top-left and bottom-left of the first table data on the table row*/
.dataTable td:first-child,
.dataTable th:first-child {
  border-radius: 10px 0 0 10px !important;
}

/*Set border-radius on the top-right and bottom-right of the last table data on the table row*/
.dataTable td:last-child,
.dataTable th:last-child {
  border-radius: 0 10px 10px 0 !important;
}
table.dataTable > thead:first-child > tr:first-child > th{
  background: #BFA253 !important;
  color: #fff !important;
}
.dataTable.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: transparent;
}
.dataTable td{
  background: #F8F5F0 !important;
  border-top: 0px !important;
  vertical-align: middle !important;

}
table.dataTable  {
    border-collapse: separate;
    border-spacing: 0 1em;
}
#userlistadmin, #orglistadmin {
    display: none;
}
.load{
  display: block;
}
div.dt-buttons {
  float: left;
  margin-bottom: 20px; 
}
#memlistadmin td:focus,#memlistadmin td:focus-visible,#memlistadmin td:focus-within {
  background: #F8F5F0 !important;
}
div.dt-buttons > .dt-button{
  width: 150px;
  border: 0px solid #00573C !important;
  background: #00573C !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 12px 0px !important;
  margin-right: 6px !important;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px !important;
}
div.dt-buttons > .dt-button:hover:not(.disabled){
  border: 0px solid #BFA253 !important;
  background: #BFA253 !important;
}
/***********design POP UP ************/
.ui-corner-all{
  border-radius: 10px !important;
}
.ui-dialog{
  background: #fff !important;
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.8);
}
.form-control[disabled] {
  background: transparent !important;
}
.ui-dialog-content{
  background: #fff !important;
  box-shadow: none !important;
  text-align: center;
  font-size: 24px;
  font-family: 'Josefin Sans', sans-serif;
}
.ui-widget-overlay{
  background: transparent !important;
}
table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
  text-align: center;
  border-radius: 10px !important;
}
.ui-dialog .ui-dialog-titlebar-close {
  display: none !important;
}
.le-checkbox {
  appearance: none;
  position: absolute;
  top:50%;
  left:5px;
  transform:translateY(-50%);
  background-color: #BFA253;
  width:30px;
  height:30px;
  border-radius:40px;
  margin:0px;
  outline: none; 
  transition:background-color .5s;
}
.le-checkbox:before {
  content:'';
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:#ffffff;
  width:20px;
  height:5px;
  border-radius:40px;
  transition:all .5s;
}

.le-checkbox:after {
  content:'';
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) rotate(-45deg);
  background-color:#ffffff;
  width:20px;
  height:5px;
  border-radius:40px;
  transition:all .5s;
}
.le-checkbox:checked {
  background-color:#00573C;
}
.le-checkbox:checked:before {
  content:'';
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) translate(-4px,3px) rotate(45deg);
  background-color:#ffffff;
  width:12px;
  height:5px;
  border-radius:40px;
}

.le-checkbox:checked:after {
  content:'';
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) translate(3px,2px) rotate(-45deg);
  background-color:#ffffff;
  width:16px;
  height:5px;
  border-radius:40px;
}
.checkdiv {
  position: relative;
  padding: 4px 8px;
  border-radius:40px;
  margin-bottom:4px;
  min-height:30px;
  padding-left:40px;
  display: flex;
  align-items: center;
}
.checkdiv:last-child {
  margin-bottom:0px;
}
.checkdiv span {
  position: relative;
  vertical-align: middle;
  line-height: normal;
}
.copy-icon::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

.copy-icon::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f328";
  color: #BFA253;
  text-align: right;
  float:right;
  cursor: pointer;
}
/* Styles for the overlay */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Styles for the dialog box */
.dialog-box {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}
.dialog-box .heading
{
  text-transform: capitalize;
}

.dialog-box .heading.success
{
  color:#00573C;
}
.dialog-box .heading.failed
{
  color:#d9534f;
}

/* Styles for the confirm button */
.dialog-confirm-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

/* Styles for the cancel button */
.dialog-cancel-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}
.btn-link
{
  color:#BFA253 !important;  
}
.btn-link:hover
{ 
  font-weight: bold;
}
/* Close button styles */
.dialog-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #6c757d;
}

.dialog-close-button:hover {
  color: #000;
}
